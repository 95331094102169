import _ from "lodash";

export function align(a, b, xAnchor, yAnchor, xPos, yPos) {

    let offsetX = 0, offsetY = 0, origin =0;
    if (xAnchor >= 0) offsetX = Math.round((b.left + (b.width * b.scaleX) * (xPos-origin)) - (a.left + (a.width * a.scaleX) * (xAnchor-origin)));
    if (yAnchor >= 0) offsetY = Math.round((b.top + (b.height * b.scaleY) * (yPos-origin)) - (a.top + (a.height * a.scaleY) * (yAnchor-origin)));

    a.left += offsetX;
    a.top += offsetY;
    a.setCoords();

}

export function scaleByBoundingRect(object, dims, settings) {

    const options = _.merge({
        proportional: true
    }, settings)

    const group = new fabric.Group([object]);
    const box = group.getBoundingRect(true);

    if ("width" in dims) {
        group.scaleX = dims.width / box.width;
        if (!dims.height && options.proportional) group.scaleY = group.scaleX;
    }

    if ("height" in dims) {
        group.scaleY = dims.height / box.height;
        if (!dims.width && options.proportional) group.scaleX = group.scaleY;
    }

    group.destroy();

    return object;
}

export function fitToBoundingRect(object, box, settings) {

    const options = _.merge({
        proportional: true
    }, settings);

    if (options.proportional) {
        scaleByBoundingRect(object, { width: box.width }, options);
        if (object.getBoundingRect(true).height > box.height) {
            scaleByBoundingRect(object, { height: box.height }, options);
        }
    } else {
        scaleByBoundingRect(object, { width: box.width, height: box.height });
    }

    setPositionByCenter(object, { x: box.left + box.width / 2, y: box.top + box.height / 2 });

    return object;
}

export function setPositionByCenter(object, position) {

    const center = object.translateToCenterPoint({ x: position.x || 0, y: position.y || 0 }, "center", "center");
    const pos = object.translateToOriginPoint(center, object.originX, object.originY);
    "x" in position && object.set({ left: pos.x });
    "y" in position && object.set({ top: pos.y });
    object.setCoords();


    return object;
}


export function normalizeAngle(angle) {
    return angle < 0 ? ((angle + 360) % 360) : angle % 360;
}


export function angle2rect(angle, sx, sy) {
    var a = sy, b = a + sx, c = b + sy,
        p = (sx + sy) * 2,
        rp = p / 360,
        pp = Math.round(((angle * rp) + (sy >> 1)) % p);

    if (pp <= a) return { x: 0, y: sy - pp };
    if (pp <= b) return { y: 0, x: pp - a };
    if (pp <= c) return { x: sx, y: pp - b };
    return { y: sy, x: sx - (pp - c) };
}

export function getCanvasTrimmedBounds(canvas) {
    function rowBlank(imageData, width, y) {
        for (let x = 0; x < width; ++x) {
            if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
        }
        return true;
    }

    function columnBlank(imageData, width, x, top, bottom) {
        for (let y = top; y < bottom; ++y) {
            if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
        }
        return true;
    }

    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let top = 0;
    let bottom = imageData.height;
    let left = 0;
    let right = imageData.width;

    while (top < bottom && rowBlank(imageData, width, top)) ++top;
    while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
    while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
    while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;

    return {
        left: left,
        top: top,
        right: right,
        bottom: bottom,
    }
}

export function getObjectRealSize(object) {
    const canvas = object.toCanvasElement();
    const bounds = getCanvasTrimmedBounds(canvas);
    return {
        x1: bounds.left,
        y1: bounds.top,
        x2: bounds.right,
        y2:bounds.bottom,
        width: bounds.right - bounds.left,
        height: bounds.bottom - bounds.top
    }
}

export function updateGroupBounds(group) {

    const w = group.width;
    const h = group.height;

    group._calcBounds(true);
    group.setCoords();

    for (const object of group._objects) {
        object.left -= (group.width - w) / 2;
        object.top -= (group.height - h) / 2;
        object.setCoords();
    }
}

export function animateProp(object, prop, from = "current", to, duration = 500) {
    fabric.util.animate({
        startValue: from == "current" ? object[prop] : from,
        endValue: to,
        duration: duration,
        onChange: (value) => {
            object.set(prop, value);
            object.setCoords();
            object.canvas && object.canvas.requestRenderAll();
        }
    })
}

export function panCanvas(canvas, x = 0, y = 0, relative = false) {

    const t = canvas.viewportTransform;

    t[4] = Math.round(relative ? t[4] + x : x);
    t[5] = Math.round(relative ? t[5] + y : y);
   
    canvas.setViewportTransform(t);
}

export function waitUntil(condition, timeout = 15000) {
    return new Promise((resolve, reject) => {
        const debouncedReject = _.debounce(() => {
            clearInterval(interval);
            reject(lang("timeoutExceeded"));
        }, timeout);


        if (condition()) {
            resolve(true);
            return;
        }

        const interval = setInterval(() => {
            if (condition()) {
                clearInterval(interval);
                debouncedReject.cancel();
                resolve(true);
            }
        }, 8);

        debouncedReject();
    })
}

export function dispatch(name, detail = {}, target = window, bubbles = true) {
    //console.log(name);
    target.dispatchEvent(
        new CustomEvent(name, {
            detail: detail,
            bubbles: bubbles,
            composed: true,
            cancelable: true,
        })
    )
}
