import Alpine from "alpinejs";
import _ from "lodash";

fabric.devicePixelRatio = 1;

fabric.Object.prototype.set({
    objectCaching: false,
    padding: 0,
    NUM_FRACTION_DIGITS: 3,
    paintFirst: "stroke",
    strokeWidth: 0,
    strokeUniform: false,
    strokeLineJoin: "round",
    strokeLineCap: "round"
})

fabric.Canvas.prototype.set({
    renderOnAddRemove: false,
    stateful: false
})

const DEFAULTS = {
    layout: 1,
    angle: 0,
    skewX: 0,
    skewY: 0,
    scale: 1,
    canvas: {
        backgroundColor: "#ffffff",
        renderOnAddRemove: false
    },
    objects: {
        bg: {
            type: "bg",
            fill: "#ffffff"
        },
        icon: {
            type: "svg",
            fill: {
                colorStops: ["#000000", "#ffffff", "#000000"],
                angle: 0
            },
            stroke: {
                colorStops: ["#cc0000", "#ffcc00"]
            },
            extra: {
                size: 128
            }
        },
        title: {
            type: "text",
            fontFamily: "Arial",
            text: "Заголовок лого",
            fontSize: 72,
            fontWeight: 700,
            angle:0,
            fill: {
                colorStops: ["#000000", "#ffffff"],
                angle: 0
            },
            extra: {
                dx:0
            }
        },
        slogan: {
            type: "text",
            fontFamily: "Arial",
            text: "Слоган",
            fontSize: 36,
            fontWeight: 300,
            fill: {
                colorStops: ["#000000", "#ffffff"],
                angle: 0
            }
        }
    }
}

export let config;

export function initStore() {
    for (const key in DEFAULTS.objects) {
        DEFAULTS.objects[key] = _.merge({},
            {
                angle: 0,
                skewX: 0,
                skewY: 0,
                fill: "#000000",
                stroke: "#000000",
                strokeWidth: 0,
                opacity: 1,
                extra: {
                    dx: 0,
                    dy: 0,
                },
            },
            DEFAULTS.objects[key]);
    }
    Alpine.store("config", _.cloneDeep(DEFAULTS));
    config = Alpine.store("config");
}