import _ from "lodash";
import { config } from "./config";
import { align, angle2rect, animateProp, dispatch, fitToBoundingRect, getObjectRealSize, normalizeAngle, panCanvas, setPositionByCenter, updateGroupBounds, waitUntil } from "./utils";
import Alpine from "alpinejs";

export let canvas;

const scene = {}
const fonts = [];
const icons = [];

let initialized = false;

export function createCanvas() {
    canvas = new fabric.StaticCanvas("canvas", config.canvas);
}

export function initLogo() {

    return new Promise((resolve, reject) => {

        for (const key in config.objects) {

            const cfg = config.objects[key];

            switch (cfg.type) {
                case "svg":
                case "bg":
                    scene[key] = new fabric.Rect({ width: 32, height: 32 });
                    scene[`${key}_copy`] = new fabric.Rect({ width: 32, height: 32 });
                    break;
                case "text":
                    scene[key] = new fabric.IText(cfg.text, { fontFamily: "Arial" });
                    scene[`${key}_copy`] = new fabric.IText(cfg.text, { fontFamily: "Arial" });
            }
        }

        for (const key in scene) {
            canvas.add(scene[key]);
        }

        applyConfig();

        loadFonts().then(() => {
            _.debounce(() => {
                initialized = true;
                rendering(true);
                resolve();
            }, 512)();
        })

    });

    //loadSprites();
}

function loadSprites() {
    fetch("./static/sprites/lib1.svg")
        .then(r => r.text())
        .then(text => {
            const container = document.getElementById("sprites");
            container.innerHTML = text;
            const icons = document.querySelectorAll("#sprites svg symbol");
            for (const icon of icons) {
                icons.push({
                    id: icon.id,
                    svg: icon.innerHTML
                })
            }
            container.remove();
        })
        .catch(e => console.error(e));
}

function loadSVG(key = "icon", src) {

}


function loadFonts() {
    const promises = [
        loadFont(config.objects.title.fontFamily),
        loadFont(config.objects.slogan.fontFamily)
    ]
    return Promise.all(promises);
}

function loadFont(family) {

    if (!family.startsWith("google:")) {
        fonts[family] = { family: family, weights: [400, 700] };
        return Promise.resolve();
    }

    if (fonts[family] instanceof Promise) {
        return fonts[family];
    }

    if (fonts[family]) return Promise.resolve();

    const fontPromise = new Promise((resolve, reject) => {

        const weights = [300, 400, 500, 600, 700];

        let opts = "ital,wght@";

        for (const weight of weights) {
            opts += `0,${weight};1,${weight};`
        }

        opts.substring(0, opts.length - 1);

        const link = document.createElement("link");

        const _family = _.last(family.split(":"));

        link.rel = "stylesheet";
        link.href = `https://fonts.googleapis.com/css?family=${encodeURI(_family)}:${opts}&display=swap`

        const placeholder = document.createElement("span");

        placeholder.style.fontFamily = _family;
        placeholder.style.width = 0;
        placeholder.style.height = 0;
        placeholder.style.position = "fixed";
        placeholder.style.pointerEvents = "none";
        placeholder.style.visibility = "hidden";

        let html = "";

        for (const weight of [300, 400, 500, 600, 700]) {
            html += `<span style="font-weight: ${weight}">${scene.title.text} ${scene.slogan.text}</span>`
        }

        placeholder.innerHTML = html;

        document.head.appendChild(link);
        document.body.appendChild(placeholder);

        let ms = 0;
        const int = setInterval(() => {
            if (document.fonts.check(`1em ${_family}`)) {
                clearInterval(int);
                fonts[family] = {
                    family: family,
                    weights: getAvailableFontWeights(_family)
                }
                resolve();
            }
            ms++;
            if (ms > 7000) {
                delete fonts[family];
                clearInterval(int);
                reject();
            }
        }, 256)
    })

    fonts[family] = fontPromise;
    return fontPromise;
}


function getAvailableFontWeights(family) {
    const weights = [];
    for (const font of document.fonts.values()) {
        if (font.family == family) weights.push(font.weight);
    }
    return _.uniq(weights).map((weight) => parseInt(weight));
}

function setFill(object, fill, fillType = "fill") {

    if (typeof fill == "string") {
        object[fillType] = fill;
        return;
    }

    const gradient = {
        colorStops: [],
        type: "linear",
        gradientUnits: "percentage"
    }

    for (let [i, stop] of fill.colorStops.entries()) {
        gradient.colorStops[i] = { color: stop, offset: i / (fill.colorStops.length - 1), opacity: 1 }
    }

    const coords = {
        start: angle2rect(fill.angle || 0, object.width, object.height)
    }

    coords.end = {
        x: object.width - coords.start.x,
        y: object.height - coords.start.y
    }

    gradient.coords = {
        x1: coords.start.x / object.width,
        y1: coords.start.y / object.height,
        x2: coords.end.x / object.width,
        y2: coords.end.y / object.height
    }


    if (object[fillType] instanceof fabric.Gradient) {
        _.merge(object[fillType], gradient)
    } else {
        object[fillType] = new fabric.Gradient(gradient);
    }
}

function applyConfig() {

    for (const key in scene) {

        const obj = scene[key];
        const cfg = config.objects[key.replace("_copy", "")];

        switch (cfg.type) {
            case "bg":
                obj.set({ width: canvas.width, height: canvas.height });
                break;
            case "svg":
                break;
            case "text":
                obj.text = cfg.text;
                obj.fontSize = cfg.fontSize;
                obj.fontWeight = cfg.fontWeight;
                obj.fontFamily = _.last(cfg.fontFamily.split(":"));;
                break;
            default: break;
        }

        if (!key.includes("copy")) {
            canvas.viewportCenterObject(obj);
            obj.scale(cfg.type == "svg" ? cfg.extra.size / obj.width : 1);
            obj.set({
                angle: 0,
                skewX: 0,
                skewY: 0,
                strokeWidth: cfg.strokeWidth,
                opacity: cfg.opacity
            }).setCoords();
        }

        obj.setCoords();

        setFill(obj, cfg.fill, "fill");
        setFill(obj, cfg.stroke, "stroke");
    }

}


export async function generate() {

    if (!initialized) return;

    console.log("generate");

    const gap = 24;
    let group, logo;


    applyConfig();

    scene.icon.visible =  scene.title.visible =  scene.slogan.visible = true;

    switch (config.layout) {
        case 1:
            align(scene.slogan, scene.title, 0, 0, 0, 1);
            group = new fabric.Group([scene.title, scene.slogan]);
            align(group, scene.icon, 0, 0.5, 1, 0.5);
            scene.icon.left -= gap;
            break;
        case 2:
            align(scene.slogan, scene.title, 1, 0, 1, 1);
            group = new fabric.Group([scene.title, scene.slogan]);
            align(group, scene.icon, 1, 0.5, 0, 0.5);
            scene.icon.left += gap;
            break;
        case 3:
            align(scene.slogan, scene.title, 0.5, 0, 0.5, 1);
            align(scene.icon, scene.title, 0.5, 1, 0.5, 0);
            scene.icon.top -= gap;
            break;
        case 4:
            align(scene.slogan, scene.title, 0.5, 1, 0.5, 0);
            align(scene.icon, scene.title, 0.5, 0, 0.5, 1);
            scene.icon.top += gap;
            break;
        case 5:
            align(scene.title, scene.icon, 1, 0.5, 0, 0.5);
            align(scene.slogan, scene.icon, 0, 0.5, 1, 0.5);
            scene.title.left -= gap;
            scene.slogan.left += gap;
            break;
        case 6:
            align(scene.title, scene.icon, 0.5, 1, 0.5, 0);
            align(scene.slogan, scene.icon, 0.5, 0, 0.5, 1);
            scene.title.top -= gap;
            scene.slogan.top += gap;
            break;
        case 7:
            align(scene.title, scene.slogan, 1, 0.5, 0, 0.5);
            align(scene.icon, scene.title, 1, 0.5, 0, 0.5);
            scene.icon.left -= gap;
            scene.slogan.left += gap;
            break;
        case 8:
            align(scene.slogan, scene.title, 0, 0.5, 1, 0.5);
            align(scene.icon, scene.slogan, 0, 0.5, 1, 0.5);
            scene.icon.left += gap;
            scene.title.left -= gap;
            break;
        case 9:
            align(scene.slogan, scene.title, 0, 0.5, 1, 0.5);
            scene.slogan.left += gap;
            group = new fabric.Group([scene.title, scene.slogan]);
            align(scene.icon, group, 0.5, 1, 0.5, 0)
            scene.icon.top -= gap;
            break;
        case 10:
            align(scene.slogan, scene.title, 0, 0.5, 1, 0.5);
            scene.slogan.left += gap;
            group = new fabric.Group([scene.title, scene.slogan]);
            align(scene.icon, group, 0.5, 0, 0.5, 1)
            scene.icon.top += gap;
            break;
        default:
            break;
    }

    group && group.destroy();

    for (const key in scene) {
        const obj = scene[key];
        const cfg = config.objects[key];
        if (key.includes("copy") || key == "bg") continue;
        obj.set({
            left: obj.left + cfg.extra.dx,
            top: obj.top + cfg.extra.dy,
            skewX: cfg.skewX,
            skewY: cfg.skewY
        })
        obj.rotate(cfg.angle);
    }

    logo = new fabric.Group([scene.icon, scene.title, scene.slogan]);

    logo.rotate(config.angle);

    const realBox = getObjectRealSize(logo);
    const box = logo.getBoundingRect();
    const pad = 24;


    let factor = 1;
    if ((realBox.width > canvas.width - (pad * 2)) || (realBox.height > canvas.height - (pad * 2))) {
        factor = Math.min((canvas.width - pad * 2) / realBox.width, (canvas.height - pad * 2) / realBox.height);
    }

    const left = (canvas.width / 2) + ((logo.left - box.left - realBox.x1) - (realBox.width / 2)) * factor;
    const top = (canvas.height / 2) + ((logo.top - box.top - realBox.y1) - (realBox.height / 2)) * factor;

    factor && logo.scale(factor);
    logo.set({
        left: left,
        top: top
    }).setCoords();

    //if (config.factor < factor) logo.scale(config.factor);

    logo.destroy();

    scene.icon.visible = scene.title.visible = scene.slogan.visible = false;

    for (const prop of ["left", "top", "scaleX", "scaleY", "angle"]) {
        for (const key of ["icon", "slogan", "title"]) {
            animateProp(scene[`${key}_copy`], prop, "current", scene[key][prop], 256);
        }
    }

    render();
}

function resetTransform(key) {
    _.merge(config.objects[key], { extra: { dx: 0, dy: 0, angle: 0, skewX: 0, skewY: 0 } });
}

export function set(path, val, opts = {}) {

    let oldVal = _.get(config, path);

    switch (path) {
        case "layout":
            _.merge(config, {
                angle: 0,
                skewX: 0,
                skewY: 0
            })
            for (const key in config.objects) {
                resetTransform(key);
            }
            break;
        default: break;
    }

    if (typeof val == "string" && val.startsWith("+")) val = oldVal + parseFloat(val.substring(1));
    if (typeof val == "string" && val.startsWith("-")) val = oldVal - parseFloat(val.substring(1));
    if (path.includes("angle")) val = normalizeAngle(val);
    if (!_.isUndefined(opts.min) && typeof val == "number" && val < opts.min) val = opts.min;
    if (!_.isUndefined(opts.max) && typeof val == "number" && val > opts.max) val = opts.max;

    _.set(config, path, val);

    generate();
}

let _renderInterval = null;

function rendering(on = true) {
    if (on) {
        if (!_renderInterval) _renderInterval = setInterval(() => {
            render();
        }, 128)
    } else {
        clearInterval(_renderInterval)
        _renderInterval = null;
    }
}

function render() {
    _renderInterval && canvas.requestRenderAll()
}

window.set = set;
window.Alpine = Alpine;
window.scene = scene;